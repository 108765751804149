import gql from "graphql-tag";

export const SaleStatsFields = gql`
  fragment SaleStatsFields on PgSaleStats {
    __typename
    nonSaleSkus
    percentSaleSkus
    saleSkus
    totalSkus
  }
`;
