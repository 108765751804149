import clientIndex, { factory } from "./client-index";
import serverIndex from "./server-index";
import { processEnvServer } from "hooks/useSsrHooks";
import { ClientType } from "./client-type";

const index = processEnvServer ? serverIndex() : clientIndex();

export const client: ClientType = index.client;
export const cache = index.cache;
export const factoryClient = factory;
export default index.client;
