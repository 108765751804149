import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo
} from "react";

type ImageMap = Record<number, number>;
type GroupHeight = Record<number, number>;
type GridMap = number[][];

interface ImageSizeContextType {
  groupHeight: GroupHeight;
  getGroup: (index: number) => number;
  trackImageHeight: (height: number, index: number) => void;
  generateGridMap: (sectionMap: number[][], cols: number) => void;
}

const ImageSizeContext = createContext<ImageSizeContextType>({
  groupHeight: {},
  getGroup: () => 0,
  trackImageHeight: () => {},
  generateGridMap: () => {}
});

function ImageSizeProvider(props: React.PropsWithChildren<{}>) {
  const [groupHeight, setGroupHeight] = useState<GroupHeight>({});
  const [imageMap, setImageMap] = useState<ImageMap>({});
  const [gridMap, setGridMap] = useState<GridMap>([]);

  useEffect(() => {
    evalGroupHeight(gridMap);
  }, [gridMap, imageMap]);

  const getGroup = useCallback(
    (index: number) => {
      return gridMap.findIndex(g => g.includes(index));
    },
    [gridMap]
  );

  const trackImageHeight = useCallback((height: number, index: number) => {
    setImageMap(prevImageMap => ({
      ...prevImageMap,
      [index]: height
    }));
  }, []);

  const evalGroupHeight = useCallback((gridMap: GridMap) => {
    const groupUpdate: GroupHeight = {};
    gridMap.forEach((row, i) => {
      if (row.every(r => imageMap[r])) {
        const maxHeight = Math.max(...row.map(r => imageMap[r]));
        groupUpdate[i] = maxHeight;
      }
    });
    setGroupHeight(groupUpdate);
  }, []);

  const generateGridMap = useCallback(
    (sectionMap: number[][], cols: number) => {
      const smap: GridMap = [];
      let row = 0;
      sectionMap.forEach(section => {
        for (let i = section[0]; i <= section[1]; i++) {
          if (!smap[row]) smap[row] = [];
          if (smap[row].length === cols) smap[++row] = [];
          smap[row].push(i);
        }
        row++;
      });
      setGridMap(smap);
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      groupHeight,
      getGroup,
      trackImageHeight,
      generateGridMap
    }),
    [groupHeight, getGroup, trackImageHeight, generateGridMap]
  );

  return <ImageSizeContext.Provider value={contextValue} {...props} />;
}

const useImageSize = () => {
  return useContext<ImageSizeContextType>(ImageSizeContext);
};

export { ImageSizeProvider, useImageSize };
