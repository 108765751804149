import gql from "graphql-tag";

export const MemberSavingsFields = gql`
  fragment MemberSavingsFields on PgMemberSaving {
    __typename
    count
    memberSavingsMin
    memberSavingsMax
  }
`;
