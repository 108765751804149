import gql from "graphql-tag";
import { PriceRangeDisplay } from "graphql-client/fragments/price-range-display";

export const CategoryProductFields = gql`
  fragment CategoryProductFields on CategoryProduct {
    __typename
    id
    displayName
    imageUrl
    imageFlip
    altImages {
      imageUrl
    }
    galleryDescription
    newProduct
    type
    priceRangeDisplay {
      ...PriceRangeDisplay
    }
    uxAttributes {
      triggerSwatchImage
      productType
      giftCert
    }
    customProduct
    pgCrop
    pgCropRules
    rhr
    pdpImageLayout
    seoDescription
  }
  ${PriceRangeDisplay}
`;
