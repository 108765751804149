import gql from "graphql-tag";

export const MembershipInfo = gql`
  fragment MembershipInfo on MembershipInfoType {
    userHasActiveMembership
    userHasCancelledMembership
    userHasExpiredMembership
    currencyCode
    membershipId
  }
`;
