import gql from "graphql-tag";

export const LineRestrictions = gql`
  fragment LineRestrictionsFragment on LineRestrictions {
    reason
    regionMatch
    regionMatchDetail
    restrictions {
      category
      countries
      postalRanges
      reason
      sku
      states
      type
    }
    restrictionsApply
    __typename
  }
`;
