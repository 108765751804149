import gql from "graphql-tag";

export const Instock_qtyFields = gql`
  fragment Instock_qtyFields on StockInfo {
    __typename
    totalSkus
    inStock
    inStockQty
  }
`;
