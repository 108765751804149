import gql from "graphql-tag";

export const BaseGallery = gql`
  fragment BaseGalleryInfo on Gallery {
    __typename
    docId
    number
    name
    conciergeName
    phoneNumber
    serviceOfferings {
      offersDesignAtelier
      offersInteriorDesign
    }
    address {
      mallName
      streetLine1
      streetLine2
      city
      county
      state
      country
      postalCode
      latitude
      longitude
      timeZoneName
    }
  }
`;

export const Gallery = gql`
  fragment GalleryInfo on Gallery {
    ...BaseGalleryInfo
    docType
    docUuid
    docCtime
    docMtime
    type
    replacementFacility
    galleryLink
    phoneNumber
    url
    generalEmailAddress
    allEmailAddresses
    leadsEmailAddresses
    description
    notes
    galleryStatus
    standardDailyHoursList {
      closeTime
      dayOfWeek
      open
      openTime
      shortNameEnUs
    }
    hours
    overrideHoursHash {
      isOpen
      openTime
      closeTime
      shortNameCode
      shortNameEnUs
    }
    collections
    hospitalityOfferings {
      offers3ArtsClubCafe
      offers3ArtsClubWineVault
      offers3ArtsClubPantryBaristaBar
      offersRHRooftopRestaurant
      offersRHCourtyardCafe
      offersRHWineVault
      offersRHPantryBaristaBar
    }
    groundsFeatures {
      hasEuropeanGardenCourtyard
      hasIndoorConservatoryPark
      hasRooftopParkConservatory
      hasGroundEstateGardens
      hasRooftopPark
      hasGardenCourtyard
    }
    parkingOfferings {
      offersOnStreet
      offersPrivateFreeLot
      offersPrivatePaidLot
      offersPublicFreeLot
      offersPublicPaidLot
      offersComplimentaryValet
      offersPaidValet
    }
    region
    isConciergeEnabled
    isRHStore
    isBCStore
    isRHCAStore
    isOperating
    heroImage
  }
  ${BaseGallery}
`;
