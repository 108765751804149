import gql from "graphql-tag";

export const Wishlist = gql`
  fragment Wishlist on WishlistType {
    __typename
    id
    guest {
      userId
      ctId
      atgUserId
      cwUserId
      email
      type
      typeId
      sourceCode
    }
    country
    postalCode
    lineItems {
      availabilityStatus
      personalizeInfo {
        feeHigh
        feeLow
        selectedFont {
          id
          displayName
          previewImage
          image
          borders {
            id
          }
        }
        selectedBorder {
          id
          image
          displayName
          minLength
          maxLength
          numberOfLines
        }
        selectedStyle {
          id
          image
          minLength
          maxLength
          numberOfLines
          displayName
          previewImage
        }
        selectedColor {
          id
          displayName
          image
        }
      }
      isMonogramFee
      isMonogrammable
      isPersonalizable
      monogram {
        styleId
        description
        maxLines
        fontCode
        fontColorCode
        borderCode
        lines
        fulfillmentCode
      }
      lineId
      quantity
      sku
      skuType
      multiSku
      multiSkuId
      brand
      displayName
      giftBoxDetail {
        giftBoxFee
        giftBoxCurrencyCode
      }
      giftWrap
      deliveryEta
      deliveryEtaStart
      deliveryEtaEnd
      coordGroup
      registryId
      spoTermsAccepted
      isMembershipSku
      productId
      product {
        displayName
        imageUrl
        targetUrl
        restrictions {
          spo
          returnPolicyMessage
          countryRestrictions
          mattressFeeLocation
          preBillMessage
          additionalMessages {
            curbsideMessage
            assemblyMessage
            giftCardMessage
            railroadMessage
            mattressFeeMessage
            cancellableMessage
            finalSaleMessage
          }
        }
      }

      price {
        itemPrice
        memberPrice
        originalMemberPrice
        priceStatus
        priceType
        currencyCode
        originalPrice
        totalPrice
        salePrice
        finalPrice
        salePriceLabel
        isOnClearance
        isOnSale
        showMemberPrice
        fees
        itemShippingSurcharge
      }
      options {
        id
        type
        value
        sortPriority
      }
      skuCustomization {
        type
        mountType
        lining
        panel
        controlType
        controlPosition
        controlLength
        tiltType
        controlAndTilt
        trim
        width
        length
        diameter
        roomLabel
        bracketColor
        rollType
      }
      imageUrl
    }
    removedItems {
      invalidRegion {
        sku
        product {
          displayName
          imageUrl
          targetUrl
          alternateImages {
            imageUrl
            caption
          }
          colorizeInfo {
            colorizable
            colorizeType
            multicolor
          }
          restrictions {
            spo
            returnPolicyMessage
            countryRestrictions
            mattressFeeLocation
            preBillMessage
            additionalMessages {
              curbsideMessage
              assemblyMessage
              giftCardMessage
              railroadMessage
              mattressFeeMessage
              cancellableMessage
              finalSaleMessage
            }
          }
        }
        options {
          id
          type
          value
        }
      }
    }
  }
`;
