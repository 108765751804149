import gql from "graphql-tag";
import { Cart } from "graphql-client-cart-broker/fragments/cart";

export const removeMembershipMutation = gql`
  mutation removeMembership($email: String!, $cartId: String!) {
    removeMembership(email: $email, cartId: $cartId) {
      ...Cart
    }
  }
  ${Cart}
`;
