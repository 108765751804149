import gql from "graphql-tag";
export const CatalogNavigationFragment = gql`
  fragment CatalogNavigationFragment on SaleNavigationElement {
    __typename
    id
    targetUrl
    displayName
    linkType
    siteId
    filter
    uxAttributes {
      selectorId
    }
    childCategories {
      id
      targetUrl
      displayName
      navImage
      linkType
      siteId
      filter
      uxAttributes {
        selectorId
      }
      childCategories {
        id
        targetUrl
        displayName
        linkType
        siteId
        filter
        uxAttributes {
          selectorId
        }
        childCategories {
          id
          targetUrl
          displayName
          linkType
          siteId
          filter
          uxAttributes {
            selectorId
          }
          childCategories {
            id
            targetUrl
            displayName
            linkType
            siteId
            filter
            uxAttributes {
              selectorId
            }
          }
        }
      }
    }
  }
`;
