import gql from "graphql-tag";

export const AddressValidation = gql`
  fragment AddressValidation on AddressListValidationOutputType {
    records {
      traceId
      addressType
      address {
        addressLine1
        addressLine2
        addressLine3
        city
        countryCode
        postalCode
        state
        traceId
      }
      warnings {
        vendorMessage {
          code
          shortDesc
          longDesc
        }
        desc
      }
      valid
    }
    totalRecords
  }
`;

export const AVSAddressValidation = gql`
  fragment AVSAddressValidation on AVSAddressListValidationOutputType {
    records {
      AddressVerified
      AvsResponseCode
      AvsResponseDecision
      ResponseStatus
      Address {
        FirstName
        LastName
        AddressLine1
        AddressLine2
        City
        StateProv
        PostalCode
        Country
        Email
        Phone
        EveningPhone
        ReferenceId
        addressType
      }
      RecomendedAddresses {
        FirstName
        LastName
        AddressLine1
        AddressLine2
        City
        StateProv
        PostalCode
        Country
        Email
        Phone
        EveningPhone
        ReferenceId
        addressType
      }
      customerMessages
      addressType
    }
    totalRecords
  }
`;
