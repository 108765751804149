import gql from "graphql-tag";
import { Cart } from "graphql-client-cart-broker/fragments/cart";

export const addMembershipMutation = gql`
  mutation addMembership(
    $email: String!
    $cartId: String!
    $createCartInfo: CreateCartInfoInput
  ) {
    addMembership(
      email: $email
      cartId: $cartId
      createCartInfo: $createCartInfo
    ) {
      ...Cart
    }
  }
  ${Cart}
`;
