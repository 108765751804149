import gql from "graphql-tag";
import { Cart } from "graphql-client-cart-broker/fragments/cart";

export const updateCartMutation = gql`
  mutation updateCart(
    $email: String!
    $cartId: String!
    $updateCartInfo: UpdateCartInfo!
  ) {
    updateCart(
      email: $email
      cartId: $cartId
      updateCartInfo: $updateCartInfo
    ) {
      ...Cart
    }
  }
  ${Cart}
`;
