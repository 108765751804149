import gql from "graphql-tag";

export const Membership = gql`
  fragment Membership on MembershipType {
    errorMessage
    __typename
    agreedMemberTermsAndCondition
    applicationType
    autoRenewCCDOrderNumber
    autoRenewOrderFailed
    autoRenewOrderFailedDateTime
    cancellationDate
    cancellationReason
    cancellationType
    contactFirstName
    contactLastName
    createdByAlternateOrderId
    email
    enrollmentDateTime
    entityCode
    expirationDateTime
    id
    isActive
    isAutoRenew
    isPhoneOrder
    memberAddress1
    memberAddress2
    memberCardAddress1
    memberCardAddress2
    memberCardCity
    memberCardCompanyName
    memberCardCountryCode
    memberCardPostalCode
    memberCardScheduledRequestDate
    memberCardState
    memberCardStatus
    memberCity
    memberCompanyName
    memberCountryCode
    memberFirstName
    memberLastName
    memberPostalCode
    memberState
    membershipCardOptIn
    membershipId
    uuid
    membershipRestoreDateTime
    membershipType
    renewEmailOptIn
    renewalCardExpirationDate
    renewalCardLast4Digits
    renewalCardType
    renewalCardUpdatedDateTime
    renewalTerm
    sendToEmail
  }
`;
