import gql from "graphql-tag";
export const Sku = gql`
  fragment Sku on ProductSku {
    __typename
    info {
      name
      longDescription
      imageUrl
      maxOrderQty
      skuPriceInfo {
        currencySymbol
        listPrice
        salePrice
        memberPrice
        tradePrice
        contractPrice
        memberOriginalPrice
        nextgenDriven
        onSale
        onClearance
        showMemberPrice
        customProductErrorCode
        customProductErrorMsg
        sendCwCustomProductCode
      }
      canadaShippable
      dropship
      shipViaCode
      hasCasingSkus
      casingProduct
      replacementCushionProduct
    }
    inventory {
      lineId # Unused
      fullSkuId
      atgSkuId
      postalCode
      inventoryCode # Unused
      inventoryStatus # Unused
      inventoryRemaining
      inventoryMessage
      itemsInStockMessage
      lineItemMessage
      needPostalCode # Unused
      postalCodeSpecific #Unused
      preOrder
      dateString
      inventoryOnHand
    }
    delivery {
      postalCode
      needPostalCode # Unused
      deliveryEstimateStatus # Unused
      deliveryEstimate
      freightExempt # Unused
      shippingSurcharge
      shippingSurchargeAmount
      freightExemptMessage
      deliveryStateMessage # Unused
      lineId # Unused
      shipVia
      unlimitedFurnitureDelivery
      currency
      isSwatch
    }
    restrictions {
      spo
      giftCertificate
      serviceSku
      monogram # Unused
      monogramMessage # Unused
      returnPolicyMessage
      preBillMessage
      additionalMessages {
        curbsideMessage # FIXME: Not being accessed properly
        assemblyMessage # FIXME: See starting on line 290 of RelatedProductCard
        giftCardMessage # FIXME
        railroadMessage # FIXME: Unused
        mattressFeeMessage # FIXME: Unused
        cancellableMessage # FIXME
        finalSaleMessage # FIXME
      }
      countryRestrictions
      mattressFeeLocation # Unused
    }
    fulfillmentEta {
      lineId
      fullSkuId
      atgSkuId
      postalCode
      inventoryCode
      inventoryStatus
      inventoryRemaining
      inventoryMessage
      itemsInStockMessage
      lineItemMessage
      needPostalCode
      postalCodeSpecific
      preOrder
      dateString
      startDateRange
      endDateRange
      eta
      lineType
    }
  }
`;

export const SkuRestrictions = gql`
  fragment SkuRestrictions on ProductSku {
    __typename
    info {
      name
      longDescription
      imageUrl
      maxOrderQty
      skuPriceInfo {
        currencySymbol
        listPrice
        salePrice
        memberPrice
        nextgenDriven
        tradePrice
        contractPrice
        onSale
        onClearance
        showMemberPrice
        customProductErrorCode
        customProductErrorMsg
      }
      canadaShippable
      dropship
    }
    delivery {
      postalCode
      needPostalCode # Unused
      deliveryEstimateStatus # Unused
      deliveryEstimate
      freightExempt # Unused
      shippingSurcharge
      shippingSurchargeAmount
      freightExemptMessage
      deliveryStateMessage # Unused
      lineId # Unused
    }
    restrictions {
      spo
      returnPolicyMessage
      preBillMessage
      countryRestrictions
    }
    inventory {
      inventoryRemaining
    }
  }
`;

export const SkuInventory = gql`
  fragment SkuInventory on ProductSku {
    __typename
    inventory {
      lineId # Unused
      fullSkuId
      atgSkuId
      postalCode
      inventoryCode # Unused
      inventoryStatus # Unused
      inventoryRemaining
      inventoryMessage
      itemsInStockMessage
      lineItemMessage
      needPostalCode # Unused
      postalCodeSpecific #Unused
      preOrder
      dateString
      failureMessage
    }
  }
`;
